.onboarding-container {
  width: 100%;
  min-height: calc(100vh - 6rem);
  background: url('^assets/imgs/onboarding/bg.png');
  //background: url('^assets/imgs/onboarding/onboarding-bg.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: $XL_BREAKPOINT) {
    background-position: left;
    background-size: cover;
  }

  .onboarding-box {
    background: $WHITE;
    box-shadow: 0px 6px 9px 0px #25252540;
    border: 8px solid #12121233;
    border-radius: 20px;
    padding: 2rem 1rem;

    .onboarding-left-col,
    .onboarding-right-col {
      padding: 1rem 3rem;
    }

    .onboarding-left-col {
      text-align: center;
      border-right: 1px solid $GRAY_ONE;
      color: $TEXT_COLOR_MUTED;
      font-size: 20px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 38px;
        color: $TEXT_COLOR;
        font-weight: 700;
      }

      .onboarding-illustration {
        margin-top: 3rem;
      }

      @media (max-width: $LG_BREAKPOINT) {
        border: none;
      }
    }

    .onboarding-right-col {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 30px;
        line-height: 38px;
      }

      .onboarding-form {
        .left-inline-input {
          padding-right: 0.5rem;

          @media (max-width: $LG_BREAKPOINT) {
            padding-right: 0;
            padding-bottom: 1rem;
          }
        }
      }

      .onboarding-btns {
        margin-top: auto;
        margin-bottom: 1rem;
        padding-top: 3rem;
      }
    }
  }
}
