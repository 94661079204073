@charset "UTF-8";
@import '~prismjs/themes/prism-solarizedlight.css';
@import '~prismjs/plugins/line-numbers/prism-line-numbers.css';
@font-face {
  font-family: "Gilroy Medium";
  src: url("/assets/common/fonts/gilroy/gilroy-medium.woff2") format("woff2"), url("/assets/common/fonts/gilroy/gilroy-medium.woff") format("woff");
}
@font-face {
  font-family: "Gilroy Semibold";
  src: url("/assets/common/fonts/gilroy/gilroy-semi-bold.woff2") format("woff2"), url("/assets/common/fonts/gilroy/gilroy-semi-bold.woff") format("woff");
}
@font-face {
  font-family: "Inter Regular";
  src: url("/assets/common/fonts/inter/inter-regular.ttf");
}
/* Burnt Orange */
/* Soft Peach/Terra Cotta */
/* Teal Blue */
/* Sky Blue */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100vh;
  top: 0 !important;
  font-size: 16px;
}

body {
  font-family: "Inter Regular", sans-serif;
  font-weight: 400;
  color: #252525;
  overflow-x: hidden;
}
@media (max-width: 992px) {
  body {
    font-size: 90%;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 85%;
  }
}
@media (max-width: 576px) {
  body {
    font-size: 80%;
  }
}

h1 {
  font-family: "Gilroy Semibold", Arial, sans-serif;
  color: #367588;
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
}

h2 {
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #121212;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
h2.light {
  color: #ffffff;
}

h3 {
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #121212;
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
}
h3.light {
  color: #ffffff;
}

h4 {
  font-family: "Gilroy Semibold", Arial, sans-serif;
  color: #367588;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
}
h4.underline {
  border-bottom: 1px solid #000000;
}

ul {
  margin: 0;
}

p {
  line-height: 34px;
  font-size: 16px;
  font-size: 18px;
}
p.sm {
  font-size: 16px;
}

a {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: #7fb3d5;
}
a:disabled, a.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
a:disabled:hover, a.disabled:hover {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

textarea {
  resize: none;
}

pre {
  background-color: #fafafc;
  border-radius: 5px;
  padding: 20px;
}

pre,
code {
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

::selection {
  background: #7fb3d5;
}

.light-link {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}
.light-link:hover, .light-link:active, .light-link:focus {
  color: #cc5500;
  transition: 0.2s;
}

.dark-link {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: #121212;
}
.dark-link:hover, .dark-link:active, .dark-link:focus {
  color: #cc5500;
  transition: 0.2s;
}

.light-action-icon {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.light-action-icon circle {
  stroke: #ffffff !important;
  transition: 0.2s !important;
}
.light-action-icon path {
  fill: #ffffff !important;
  transition: 0.2s !important;
}
.light-action-icon:hover circle, .light-action-icon:active circle {
  stroke: #cc5500 !important;
  transition: 0.2s !important;
}
.light-action-icon:hover path, .light-action-icon:active path {
  fill: #cc5500 !important;
  transition: 0.2s !important;
}

.dark-action-icon {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.dark-action-icon circle {
  stroke: #121212 !important;
  transition: 0.2s !important;
}
.dark-action-icon path {
  fill: #121212 !important;
  transition: 0.2s !important;
}
.dark-action-icon:hover circle, .dark-action-icon:active circle {
  stroke: #cc5500 !important;
  transition: 0.2s !important;
}
.dark-action-icon:hover path, .dark-action-icon:active path {
  fill: #cc5500 !important;
  transition: 0.2s !important;
}

.primary-color-action-icon {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.primary-color-action-icon circle {
  stroke: #cc5500 !important;
  transition: 0.2s !important;
}
.primary-color-action-icon path {
  fill: #cc5500 !important;
  transition: 0.2s !important;
}
.primary-color-action-icon:hover circle, .primary-color-action-icon:active circle {
  stroke: #cc5500 !important;
  transition: 0.2s !important;
}
.primary-color-action-icon:hover path, .primary-color-action-icon:active path {
  fill: #cc5500 !important;
  transition: 0.2s !important;
}

.secondary-color-action-icon {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.secondary-color-action-icon circle {
  stroke: #367588 !important;
  transition: 0.2s !important;
}
.secondary-color-action-icon path {
  fill: #367588 !important;
  transition: 0.2s !important;
}
.secondary-color-action-icon:hover circle, .secondary-color-action-icon:active circle {
  stroke: #4494ad !important;
  transition: 0.2s !important;
}
.secondary-color-action-icon:hover path, .secondary-color-action-icon:active path {
  fill: #4494ad !important;
  transition: 0.2s !important;
}

.tertiary-color-action-icon {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
}
.tertiary-color-action-icon circle {
  stroke: #7fb3d5 !important;
  transition: 0.2s !important;
}
.tertiary-color-action-icon path {
  fill: #7fb3d5 !important;
  transition: 0.2s !important;
}
.tertiary-color-action-icon:hover circle, .tertiary-color-action-icon:active circle {
  stroke: #a5cae2 !important;
  transition: 0.2s !important;
}
.tertiary-color-action-icon:hover path, .tertiary-color-action-icon:active path {
  fill: #a5cae2 !important;
  transition: 0.2s !important;
}

.page-container {
  padding: 2rem !important;
}

.title {
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #121212;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
}
.title.light {
  color: #ffffff;
}
.title.blue {
  color: #367588;
}
.title.small {
  font-size: 30px;
}
@media (max-width: 992px) {
  .title {
    font-size: 30px;
  }
  .title.small {
    font-size: 25px;
  }
}

.subhead {
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #333333;
  font-size: 1.375rem !important;
  font-weight: 600;
  margin-bottom: 1.5rem !important;
}

.dark-heading {
  font-size: 30px;
  font-weight: 500;
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #333333;
}
@media (max-width: 992px) {
  .dark-heading {
    font-size: 20px;
  }
}

.dark-heading-caption {
  font-size: 22px;
  font-weight: 500;
}
.dark-heading-caption.sm {
  font-size: 18px;
}
@media (max-width: 992px) {
  .dark-heading-caption {
    font-size: 16px;
  }
  .dark-heading-caption.sm {
    font-size: 14px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-relative {
  position: relative;
}

.no-pad {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-pad-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-initial {
  cursor: initial;
}

.flex {
  display: flex;
}

.inline-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-75 {
  max-width: 75%;
}

.w-100 {
  width: 100%;
}

.text-xsmall {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-normal {
  font-size: 16px;
}

.text-large {
  font-size: 18px;
}

.text-xlarge {
  font-size: 20px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-dark {
  color: #333333;
}

.text-muted {
  color: #5e6366;
}

.text-light {
  color: #999999;
}

.black {
  color: #000000;
}

.green {
  color: #21af63;
}

.green-two {
  color: #00b192;
}

.green-three {
  color: #54aa7a;
}

.green-bg {
  background: #21af63;
}

.green-2-bg {
  background: #00b192;
}

.red {
  color: #f54b52;
}

.red-bg {
  background: #f54b52;
}

.orange {
  color: #fe7e0e;
}

.yellow {
  color: #fadb14;
}

.secondary-color {
  color: #367588;
}

.secondary-color-bg {
  background: #367588;
}

.tertiary-color {
  color: #7fb3d5;
}

.tertiary-color-bg {
  background: #7fb3d5;
}

.font-primary {
  font-family: "Inter Regular", sans-serif !important;
}

.font-secondary {
  font-family: "Gilroy Medium", Arial, sans-serif !important;
}

.font-secondary-semibold {
  font-family: "Gilroy Semibold", Arial, sans-serif !important;
}

.gray-bottom-border {
  border-bottom: 1px solid #e6e1e5;
}

.gray-top-border {
  border-top: 1px solid #e6e1e5;
}

.gray-bottom-border-heavy {
  border-bottom: 2px solid #e6e1e5;
}

.monospace {
  font-family: monospace;
}

.mr-p5 {
  margin-right: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.mt-auto {
  margin-top: auto;
}

.mt-p5 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mb-p5 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-1p5 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.my-p5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-1p5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-1p5 {
  padding: 1.5rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.svg-fluid {
  width: 100%;
  height: auto;
}

.word-break-all {
  word-break: break-all;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.responsive-table .td-label {
  font-weight: bold;
  display: none;
}
@media (max-width: 992px) {
  .responsive-table th,
  .responsive-table thead {
    display: none;
  }
  .responsive-table table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
    width: 100%;
  }
  .responsive-table .td-label {
    display: block;
    margin-bottom: 10px;
  }
}
.responsive-table .td-actions {
  display: flex;
  align-items: center;
}
@media (max-width: 1400px) {
  .responsive-table .td-actions {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.form-field {
  display: flex;
  margin-bottom: 2rem;
}
.form-field.mb-1 {
  margin-bottom: 1.5rem;
}
.form-field.inline {
  flex-direction: column;
}
.form-field.inline .form-label {
  padding-top: 0px;
}
.form-field.inline .form-input {
  position: relative;
  max-width: 700px;
  min-width: 700px;
}
.form-field.inline .form-input .ant-picker {
  width: 100%;
}
.form-field.inline .form-input .tags {
  max-width: 700px;
}
@media (max-width: 1150px) {
  .form-field.inline .form-input {
    min-width: 100%;
  }
}
@media (max-width: 992px) {
  .form-field {
    flex-direction: column;
  }
}
.form-field .form-label {
  padding-top: 10px;
  padding-bottom: 8px;
  min-width: 250px;
}
.form-field .form-input {
  position: relative;
  max-width: 500px;
  min-width: 500px;
}
@media (max-width: 1150px) {
  .form-field .form-input {
    min-width: 100%;
  }
}
.form-field .form-input.tags-input .cdk-overlay-pane {
  display: none;
}
.form-field .form-input .ant-picker {
  width: 100%;
}
.form-field .form-input .select-items-selected {
  position: absolute;
  top: 7px;
  left: 10px;
}
.form-field .form-input .tags {
  max-width: 500px;
}
.form-field .form-input .form-input-description {
  line-height: 1.5;
  color: #999999;
  font-size: 0.8rem;
}

.form-group-label {
  color: #5e6366;
  font-size: 14px;
}
.form-group-label .required {
  color: #f54b52;
}

.form-fields-inline {
  display: flex;
  gap: 15px;
}
.form-fields-inline .form-field-inline {
  width: 50%;
}
@media (max-width: 992px) {
  .form-fields-inline {
    flex-direction: column;
  }
  .form-fields-inline .form-field-inline {
    width: 100%;
  }
}

.form-label-w-icon {
  display: flex;
  align-items: center;
}
.form-label-w-icon .label-icon {
  position: relative;
  top: 6px;
  left: 2px;
}

.shadow-box {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
}
.shadow-box.light-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #f0f0f0;
}

.card {
  border: 0.4px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.card .card-title {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card .card-title h4 {
  margin: 0;
  padding: 0;
}
.card .card-body {
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
}

.dots {
  font-size: 4px;
  text-align: center;
  margin: 2rem 0;
}
.dots span {
  margin: 0 2rem;
}

.circle {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  margin-right: 8px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
}

.status-code-wrap {
  border-radius: 15px;
  color: #ffffff;
  font-weight: bold;
  padding: 1px 15px 0px 15px;
  display: inline-block;
}

.tooltip-template strong {
  font-size: 12px;
  font-family: "Gilroy Medium", Arial, sans-serif;
}
.tooltip-template li,
.tooltip-template div {
  margin-top: 5px;
  font-size: 12px;
  list-style: none;
  font-family: "Inter Regular", sans-serif;
}

.verification-code {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.verification-code input::-webkit-outer-spin-button,
.verification-code input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.verification-code input[type=number] {
  -moz-appearance: textfield;
}
.verification-code input {
  width: 45px !important;
  height: 45px;
  border: none;
  border-bottom: 1px solid #000000;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  color: #252525;
  text-align: center;
  margin-right: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.2s;
}
.verification-code input:focus, .verification-code input:hover {
  transition: 0.2s;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #cc5500 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.search-filter-field ::placeholder {
  font-size: 16px;
  color: #999999;
}
.search-filter-field input {
  max-width: 100%;
  min-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 45px;
  padding: 20px 0px 20px 60px;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  color: #5e6366;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center left;
  background-position-x: 1em;
  background-size: 1.5em;
  background-image: url("^assets/common/imgs/icons/search-icon.svg");
}
@media only screen and (max-width: 550px) {
  .search-filter-field input {
    min-width: 100%;
  }
}
.search-filter-field input:hover {
  border: solid 1px rgba(54, 117, 136, 0.65);
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.15);
  transition: 0.5s;
}
.search-filter-field input:focus {
  border: solid 1px rgba(54, 117, 136, 0.9) !important;
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.5) !important;
  transition: 0.5s;
  outline: none;
}

.select-filter-fields {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .select-filter-fields {
    justify-content: start;
  }
}
@media (max-width: 768px) {
  .select-filter-fields {
    margin-bottom: 2rem;
  }
}
.select-filter-fields nz-select {
  margin-right: 7px;
}
.select-filter-fields .ant-select-selection-placeholder {
  color: #333333;
}
.select-filter-fields .ant-select,
.select-filter-fields .ant-select-selector,
.select-filter-fields .ant-select-selector:hover,
.select-filter-fields .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  max-width: 150px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: max-content !important;
  padding: 0 5px 0 0 !important;
}

.table-total-wrap {
  border-radius: 15px;
  color: #7fb3d5;
  border: 1px solid #7fb3d5;
  padding: 0px 10px;
  display: inline-block;
}
@media (max-width: 1300px) {
  .table-total-wrap .table-total-label {
    display: none;
  }
}
@media (max-width: 992px) {
  .table-total-wrap .table-total-label {
    display: inline-block;
  }
}

.info-card-row {
  min-height: 500px;
  height: max-content;
  padding: 0;
}
@media (max-width: 768px) {
  .info-card-row {
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .info-card-row {
    margin: 0 auto;
    width: 100%;
  }
}
.info-card-row .info-card-col {
  max-width: 500px;
  min-height: 500px;
  padding-right: 2rem;
  padding-bottom: 4rem;
}
@media (max-width: 992px) {
  .info-card-row .info-card-col {
    margin-top: 2rem;
  }
}
.info-card-row .info-card-col .info-card {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 5px;
}
.info-card-row .info-card-col .info-card .info-card-content {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 5rem 1.5rem 2rem 1.5rem;
}
.info-card-row .info-card-col .info-card .info-card-content .info-card-excerpt {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100% - 135px);
  padding-top: 1rem;
  font-size: 16px;
}
.info-card-row .info-card-col .info-card .info-card-content .info-card-excerpt .info-card-title {
  font-family: "Gilroy Medium", Arial, sans-serif;
  color: #367588;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 1rem;
}
.info-card-row .info-card-col .info-card .info-card-content .info-card-excerpt p {
  color: #252525;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 992px) {
  .info-card-row .info-card-col .info-card .info-card-content .info-card-excerpt p {
    font-size: 1.125rem;
  }
}
.info-card-row .info-card-col .info-card .info-card-content .info-card-excerpt button {
  margin-top: 2rem;
  padding: 0.5rem 1.5rem;
}
.info-card-row .info-card-col .info-card .info-card-footer {
  width: 100%;
  padding: 1rem;
  text-align: center;
  font-weight: bold;
}
.info-card-row .info-card-col .info-card .info-card-footer .info-card-tool-title {
  color: #cc5500;
  font-family: "Gilroy Medium", Arial, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  font-weight: normal;
}

.sub-nav-container {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}
.sub-nav-container .sub-nav-menu {
  background: #e6f1f3;
  padding: 1rem 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
}
.sub-nav-container .sub-nav-menu hr {
  display: none;
}
.sub-nav-container .sub-nav-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem 2rem;
}

html {
  --antd-wave-shadow-color: $PRIMARY_COLOR_VARIANT !important;
}

.ant-layout-footer {
  color: #252525;
  padding: 0;
}

.ant-layout {
  background: transparent;
}

.ant-layout-header {
  color: #252525;
  padding: 0;
  height: max-content;
  line-height: unset;
  background: transparent;
}

.ant-menu-vertical,
.ant-menu-horizontal {
  border: none;
}

.ant-menu,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent !important;
}

.ant-menu-item:hover::after, .ant-menu-item:not(:hover)::after,
.ant-menu-submenu:hover::after,
.ant-menu-submenu:not(:hover)::after {
  border: none !important;
}

.ant-menu-submenu .ant-menu-item {
  border-bottom: none !important;
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-selected > span,
.ant-menu-submenu-selected i,
.ant-menu-item-selected a,
.ant-menu-submenu-selected .ant-menu-submenu-title:after {
  color: #cc5500 !important;
}

.ant-menu-sub {
  padding: 0.5rem 0 !important;
}

.ant-menu-submenu-popup {
  background-color: #ffffff;
  position: absolute !important;
  z-index: 10 !important;
  padding-top: 0 !important;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  display: none;
}
.ant-menu-submenu-popup a:hover, .ant-menu-submenu-popup a.active {
  color: #cc5500;
  text-decoration: none;
}

.ant-switch:focus, .ant-switch:active {
  box-shadow: 0 0 0 2px rgba(0, 177, 146, 0.3);
}

.ant-switch-checked {
  background-color: rgba(0, 177, 146, 0.7);
}

.ant-collapse-borderless {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}
.ant-collapse-borderless .ant-collapse-header {
  height: max-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  margin-left: 0.25rem !important;
  padding-right: 50px !important;
  color: #333333 !important;
}
.ant-collapse-borderless svg {
  transition: 0.2s;
}
.ant-collapse-borderless .ant-collapse-item-active .custom-collapse-expand-icon svg {
  transform: rotate(180deg) !important;
  transition: 0.2s;
}
.ant-collapse-borderless .ant-collapse-content-box {
  margin-left: 4px;
  font-size: 18px;
  text-align: left;
  color: #252525;
}

.ant-breadcrumb a {
  transition: 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: #7fb3d5;
}
.ant-breadcrumb a:hover {
  color: #a5cae2;
  text-decoration: underline;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  color: #252525;
}
.ant-breadcrumb nz-breadcrumb-item:last-child a {
  color: #252525;
  cursor: initial;
}
.ant-breadcrumb nz-breadcrumb-item:last-child a:hover {
  text-decoration: none;
}

.ant-tooltip-inner {
  font-family: "Inter Regular", sans-serif !important;
  padding: 17px 15px 15px 15px;
  color: #333333;
  background-color: #ffffff;
  font-size: 11px;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: $WHITE !important;
  background-color: #ffffff !important;
}

.ant-popover-arrow {
  display: none;
}

.ant-alert {
  background: #ffffff;
  border-radius: 5px;
}
.ant-alert .ant-alert-message {
  color: #333333;
  font-family: "Gilroy Medium", Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.ant-alert .ant-alert-description {
  margin-top: 5px;
  font-size: 16px;
}
.ant-alert .anticon {
  font-size: 18px;
  margin-right: 10px;
}
.ant-alert.ant-alert-error {
  border: 2px solid #f54b52;
}
.ant-alert.ant-alert-error .anticon {
  color: #f54b52;
}
.ant-alert.ant-alert-info {
  border: 2px solid #7fb3d5;
}
.ant-alert.ant-alert-info .anticon {
  color: #7fb3d5;
}
.ant-alert.ant-alert-warning {
  border: 2px solid #fe7e0e;
}
.ant-alert.ant-alert-warning .anticon {
  color: #fe7e0e;
}
.ant-alert.ant-alert-success {
  border: 2px solid #00b192;
}
.ant-alert.ant-alert-success .anticon {
  color: #00b192;
}

.ant-modal .ant-modal-close .anticon {
  color: #7fb3d5;
}

.ant-modal-mask {
  background: rgba(94, 99, 102, 0.4);
}

.ant-picker svg {
  fill: #7fb3d5;
}

.ant-picker:hover,
.ant-input:hover,
.ant-input-affix-wrapper:hover:hover {
  border: solid 1px rgba(54, 117, 136, 0.65);
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.15);
}

.ant-picker-focused:not(.ant-input-group-wrapper),
.ant-input-affix-wrapper-focused:not(.ant-input-group-wrapper),
.ant-input-affix-wrapper:focus:not(.ant-input-group-wrapper),
.ant-input-focused:not(.ant-input-group-wrapper),
.ant-input:focus:not(.ant-input-group-wrapper) {
  border: solid 1px rgba(54, 117, 136, 0.9) !important;
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.5) !important;
}

.ant-input {
  min-height: 40px;
}
.ant-input::placeholder {
  font-size: 0.9rem;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
}
.ant-input-affix-wrapper .ant-input {
  padding: 4px 11px !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper .ant-input:hover, .ant-input-affix-wrapper .ant-input:active, .ant-input-affix-wrapper .ant-input:focus {
  border: none !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.ant-input-affix-wrapper .password-visiblity-toggle-icon {
  color: #7fb3d5;
}

.ant-select,
.ant-select-single {
  position: relative;
  top: 1px;
  width: 100%;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 41px !important;
}
.ant-select .ant-select-selection-placeholder,
.ant-select-single .ant-select-selection-placeholder {
  font-size: 0.9rem !important;
}
.ant-select .ant-select-selection-item div,
.ant-select .ant-select-selection-item span,
.ant-select-single .ant-select-selection-item div,
.ant-select-single .ant-select-selection-item span {
  display: flex;
  align-items: center;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba(204, 85, 0, 0.2) !important;
  font-weight: normal;
}

.ant-select-selected-icon {
  display: none;
}

.ant-select-selector:hover {
  border: solid 1px rgba(54, 117, 136, 0.65) !important;
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.15) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: solid 1px rgba(54, 117, 136, 0.9) !important;
  box-shadow: 0 0 0 2px rgba(54, 117, 136, 0.5) !important;
}

.ant-select-focused {
  border-radius: 5px !important;
}

.cdk-virtual-scroll-spacer {
  display: none;
}

.ant-select-selection-item-content {
  font-size: 0.8rem;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #cc5500 !important;
}
.ant-radio-checked .ant-radio-inner:after {
  background-color: #cc5500;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #cc5500;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #cc5500;
  border-color: #cc5500;
}

.ant-tag {
  margin-top: 5px;
}

.ant-tag,
.ant-select-multiple .ant-select-selection-item {
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 15px;
  background: #cc5500;
  color: #ffffff;
}
.ant-tag .anticon,
.ant-select-multiple .ant-select-selection-item .anticon {
  color: #ffffff !important;
}

.nz-select-tags-dropdown {
  display: none;
}

.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-prev {
  cursor: pointer;
}
.ant-pagination .ant-pagination-next svg path,
.ant-pagination .ant-pagination-prev svg path {
  fill: #367588;
  transition: 0.2s;
}
.ant-pagination .ant-pagination-disabled svg {
  cursor: not-allowed !important;
}
.ant-pagination .ant-pagination-disabled svg path {
  fill: #e6e1e5 !important;
}
.ant-pagination .ant-pagination-item {
  border: none;
}
.ant-pagination .ant-pagination-item div {
  margin: 0.6rem 1rem;
  border-radius: 50%;
  border: none;
  transition: 0.2s;
  width: 30px;
  height: 30px;
}
.ant-pagination .ant-pagination-item div a {
  color: #252525;
  transition: 0.2s;
}
.ant-pagination .ant-pagination-item:hover div {
  background-color: #367588;
  color: #ffffff;
  transition: 0.2s;
}
.ant-pagination .ant-pagination-item:hover div a {
  color: #ffffff;
  transition: 0.2s;
}
.ant-pagination .ant-pagination-item-active div {
  background-color: #367588;
}
.ant-pagination .ant-pagination-item-active div a {
  color: #ffffff;
}

.ant-skeleton-input {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px;
}

.ant-spin-nested-loading {
  position: unset;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.mint-form-field-help {
  color: #f54b52;
  background-color: transparent !important;
  border: 0px solid #f54b52 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.mint-form-field-help p {
  font-size: 0.8rem !important;
}
.mint-form-field-help.has-lateral {
  width: 100% !important;
  margin-left: 0 !important;
}

.mint-form-field-invalid {
  border: 1px solid #f54b52 !important;
  box-shadow: none !important;
  outline: none !important;
}
.mint-form-field-invalid.ant-input:focus {
  border: 1px solid #f54b52 !important;
  box-shadow: none !important;
  outline: none !important;
}
.mint-form-field-invalid .ant-input,
.mint-form-field-invalid .ant-input-focused,
.mint-form-field-invalid .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.mint-form-field-invalid .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.mint-form-field-invalid .ant-input:hover, .mint-form-field-invalid .ant-input:focus, .mint-form-field-invalid .ant-input:active,
.mint-form-field-invalid .ant-input-focused:hover,
.mint-form-field-invalid .ant-input-focused:focus,
.mint-form-field-invalid .ant-input-focused:active,
.mint-form-field-invalid .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.mint-form-field-invalid .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector:focus,
.mint-form-field-invalid .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector:active,
.mint-form-field-invalid .ant-select-selector:hover,
.mint-form-field-invalid .ant-select-selector:focus,
.mint-form-field-invalid .ant-select-selector:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.mint-form-field-description {
  color: #999999;
}
.mint-form-field-description p {
  font-size: 0.8rem;
}

mint-plain-text .align-characters-right {
  color: #999999;
}

mint-input-phone .ant-input-group .ant-select {
  width: 90px !important;
  font-size: 14px;
}

.mint-input-layout label {
  font-size: 0.9rem;
  margin-top: auto;
  margin-bottom: auto;
}
.mint-input-layout label sup {
  color: #f54b52;
}

code .block {
  position: relative;
}

code i.caret {
  position: absolute;
  font-style: normal;
  cursor: pointer;
  width: 10px;
  height: 10px;
  top: -3px;
  left: -12px;
  color: #ccc;
}

code i.caret:before {
  content: "▸";
}

code .block-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  max-height: 18px;
  max-width: 45px;
  color: #ccc;
}

code .block-wrapper > * {
  opacity: 0;
}

code .block-wrapper:before {
  content: "...";
  top: -2px;
  position: absolute;
  left: 3px;
}

code .block-wrapper:after {
  top: 0px;
  position: absolute;
  right: 0;
}

code .block-keyed-object > .block-wrapper:after,
code .block-object > .block-wrapper:after {
  content: "}";
}

code .block-keyed-array > .block-wrapper:after,
code .block-array > .block-wrapper:after {
  content: "]";
}

code .block-object {
  white-space: pre-wrap;
}

code .block.open > .block-wrapper {
  display: initial;
}

code .block.open > .block-wrapper > * {
  opacity: 1;
}

code .block.open > .block-wrapper:before,
code .block.open > .block-wrapper:after {
  display: none;
}

code .block.open > i.caret:before {
  transform: rotate(90deg);
}

pre[class*=language-] {
  background: #fafafc;
}

.onboarding-container {
  width: 100%;
  min-height: calc(100vh - 6rem);
  background: url("^assets/imgs/onboarding/bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1200px) {
  .onboarding-container {
    background-position: left;
    background-size: cover;
  }
}
.onboarding-container .onboarding-box {
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(37, 37, 37, 0.2509803922);
  border: 8px solid rgba(18, 18, 18, 0.2);
  border-radius: 20px;
  padding: 2rem 1rem;
}
.onboarding-container .onboarding-box .onboarding-left-col,
.onboarding-container .onboarding-box .onboarding-right-col {
  padding: 1rem 3rem;
}
.onboarding-container .onboarding-box .onboarding-left-col {
  text-align: center;
  border-right: 1px solid #e6e1e5;
  color: #5e6366;
  font-size: 20px;
  display: flex;
  flex-direction: column;
}
.onboarding-container .onboarding-box .onboarding-left-col h1 {
  font-size: 38px;
  color: #252525;
  font-weight: 700;
}
.onboarding-container .onboarding-box .onboarding-left-col .onboarding-illustration {
  margin-top: 3rem;
}
@media (max-width: 992px) {
  .onboarding-container .onboarding-box .onboarding-left-col {
    border: none;
  }
}
.onboarding-container .onboarding-box .onboarding-right-col {
  display: flex;
  flex-direction: column;
}
.onboarding-container .onboarding-box .onboarding-right-col h1 {
  font-size: 30px;
  line-height: 38px;
}
.onboarding-container .onboarding-box .onboarding-right-col .onboarding-form .left-inline-input {
  padding-right: 0.5rem;
}
@media (max-width: 992px) {
  .onboarding-container .onboarding-box .onboarding-right-col .onboarding-form .left-inline-input {
    padding-right: 0;
    padding-bottom: 1rem;
  }
}
.onboarding-container .onboarding-box .onboarding-right-col .onboarding-btns {
  margin-top: auto;
  margin-bottom: 1rem;
  padding-top: 3rem;
}